(() => {
    function provideHeaderSizeGlobally() {
        const root = document.documentElement;
        const header = document.querySelector('header.header');
        const step = 100; // stepping resize recalculations to avoid browser overload
        function setHeight() {
            root.style.setProperty('--header-height', header.offsetHeight + 'px');
        }
        let checkPoint = 0;

        if (typeof root.style.setProperty !== 'undefined' && header) {
            setHeight(); // once on init

            window.addEventListener('resize', e => {
                if (Math.abs(window.innerWidth - checkPoint) > step) {
                    // changes will be applied only about every {step} pixels not to affect performance - @todo conisder time stepping instead of spatial
                    setHeight();
                    checkPoint = window.innerWidth;
                }
            });
        } else {
            console.warn(
                'No values for --header-height will be provided as either css vars not supported or no header is present'
            );
        }
    }

    function headerMenusMobileMode() {
        // modifying open/close behaviour on mobile

        const backdropNeeders = ['search-overlay', 'menu'];

        function beforeShowFixedPanels(e) {
            const isTarget = backdropNeeders.some(className => e.detail.element.classList.contains(className));
            if (isTarget) {
                document.body.classList.add('lock-screen');
                UX.viewToggler.hideAll();
            }
        }

        function beforeHideFixedPanels(e) {
            document.body.classList.remove('lock-screen');
        }

        $(document)
            .on('screen-md-on', e => {
                document.addEventListener('viewToggler.beforeShow', beforeShowFixedPanels);
                document.addEventListener('viewToggler.beforeHide', beforeHideFixedPanels);
            })
            .on('screen-md-off', e => {
                document.removeEventListener('viewToggler.beforeShow', beforeShowFixedPanels);
                document.removeEventListener('viewToggler.beforeHide', beforeHideFixedPanels);
            });
    }

    function focusControl() {
        document.addEventListener('viewToggler.afterShow', e => {
            if (e.detail.element.classList.contains('search-overlay')) {
                const input = e.detail.element.querySelector('.active input[type="search"]');
                if (input) {
                    e.detail.element.addEventListener(
                        'transitionend',
                        () => {
                            // Seems to be impossible to set a focus on an element under transitions. Have wait for it to end
                            input.focus();
                        },
                        {once: true}
                    );
                }
            }
        });
    }
    function nestedListToggler() {
        $('.icon-triangle-bottom').on('click', function (e) {
            if (UX.header.isMobile) {
                e.preventDefault();
                const el = $(this);
                el.closest('li').hasClass('openList')
                    ? el.closest('li').removeClass('openList')
                    : el.closest('li').addClass('openList');
            }
        });
    }

    function headerViewPort() {
        $(document).on(UX.header.vPort + '-on', function () {
            UX.header.isMobile = true;
        });

        $(document).on(UX.header.vPort + '-off', function () {
            $('#main_botton_nav li').removeClass('openList');
            document.body.classList.remove('lock-screen');
            UX.header.isMobile = false;
        });
    }

    UX.header = {
        vPort: 'screen-md',
        isMobile: parseInt(UX.grid.screenSm) >= $(window).width(),
        init: function () {
            provideHeaderSizeGlobally();
            headerMenusMobileMode();
            focusControl();
            headerViewPort();
            nestedListToggler();
        },
    };
})();
